<template>
  <section>
    <div class="many-map">
      <i id="results-con" />
      <div class="many-map--listing">
        <SearchManyListSorting
          v-sticky="{ zIndex: 1999, stickyTop: 0 }"
          class="many-map--sorting-bar"
          :layoutMode="['mobile']"
          @search="handleSearch" />
        <div class="many-map--search-bar"
          v-sticky="{ zIndex: 1999, stickyTop: 57 }">
          <div>
            <keep-alive>
              <SearchBarManyMobile
                :queryParams="lv2State.queryParams"
                locationColCss="is-12"
                @search="handleSearch"/>
            </keep-alive>
          </div>
        </div>
        <PropertyList
          class="many-map--results"
          :nights="nights"
          :isLoading="isSearching"
          :availability="availability"
          layout="simple"
          :layoutMode="['mobile']"
          @selected="handleSelectedProperty"
          @heroImageClicked="handleSelect"/>
        <SearchManyPagination @search="handleSearch" v-if="!isSearching" v-bind="availability.pagination" />
      </div>
      <div class="many-map--canvas">
        <div class="map-wrapper">
          <SearchMapCanvas :isLoading="isSearching" :results="availability.results" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import smoothScroll from '../plugins/smoothScroll'
import SearchMapCanvas from '../components/SearchMapCanvas'
import SearchManyListSorting from '../components/SearchManyListSorting'
import PropertyList from '../components/PropertyList'
import SearchManyPagination from './SearchManyPagination'

export default {
  name: 'SearchManyMapWide',
  components: {
    [SearchMapCanvas.name]: SearchMapCanvas,
    'SearchBarManyMobile': () => import(/* webpackChunkName: "mobile" */'../components/SearchBarManyMobile'),
    [SearchManyListSorting.name]: SearchManyListSorting,
    [PropertyList.name]: PropertyList,
    [SearchManyPagination.name]: SearchManyPagination
  },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    selected: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      nights: 1,
      map: undefined
    }
  },
  computed: {
    availability () {
      return this.lv2State.searchMany.availability
    },
    isSearching () {
      return this.lv2State.searchMany.isSearching
    }
  },
  activated () {
    // Scroll to the property
    if (this.selected) {
      setTimeout(() => {
        this.scrollToItem()
      }, 100)
    }
  },
  methods: {
    handleSearch (val) {
      this.$emit('search', val)
    },
    handleSelect (propertyPackage) {
      this.$emit('select', propertyPackage)
    },
    handleSelectedProperty ({ property, packages }) {
      const joinedGallery = [].concat(
        [{ s: property.heroImage, m: property.heroImage }],
        property.gallery)
      this.$lv2Store.setSearchOne({ property: { ...property, gallery: joinedGallery }, availability: { packages }, reviews: property.reviews })
    },
    scrollToItem () {
      if (this.selected) {
        const domId = '#pc' + this.selected
        smoothScroll.scrollTo(domId, { offset: -150 })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  $many-map--listing-width: 35%;
  .many-map {
    display: flex;
  }
  .many-map--search-bar {
    border-top: 1px solid $list-border-color;
    border-bottom: 1px solid $list-border-color;
    // padding: $bleed * 2 0;
    // padding-bottom: 0;
    background-color: $white-ter;
  }
  .many-map--sorting-bar {
    border-top: 1px solid $list-border-color;
    padding: $bleed $bleed $bleed $bleed * 2;
    background-color: $white-ter;
  }
  .many-map--listing {
    width: $many-map--listing-width;
  }
  .many-map--listing /deep/ .search-bar-mobile--pop-content {
    width: $many-map--listing-width;
    height: 100%;
    // margin: auto;
    z-index: $_jh_fullscreen-z-index;
  }
  .many-map--results {
    padding-top: $bleed * 2;
  }
  .many-map--canvas {
    // float: right;
    // margin-left: $many-map--listing-width;
    // z-index: 100;
    width: 100% - $many-map--listing-width;
  }
  .map-wrapper {
    position: sticky;
    top: 0;
    height: 100vh;
  }
</style>
