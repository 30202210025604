<template>
  <GoolgeMapLoader
    :apiKey="apiKey"
    :mapConfig="mapConfig"
    id="map-canvas">
    <template slot-scope="{ google, map, popUp }">
      <GoogleOverlayPropertyPrice
        v-for="m in results"
        v-bind="m"
        :google="google"
        :map="map"
        :popUp="popUp"
        @loaded="handleMapLoaded"
        :key="m.propertyCode"
      />
    </template>
  </GoolgeMapLoader>
</template>

<script>
import GoogleMapLoader from '../components/google/GoogleMapLoader'
import GoogleOverlayPropertyPrice from '../components/google/GoogleOverlayPropertyPrice'
import EventBus from '../EventBus'

export default {
  name: 'SearchMapCanvas',
  components: {
    [GoogleMapLoader.name]: GoogleMapLoader,
    [GoogleOverlayPropertyPrice.name]: GoogleOverlayPropertyPrice
  },
  props: {
    results: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      temp: [],
      apiKey: process.env.VUE_APP_GOOLE_MAP_KEY,
      initialMapConfig: {
        zoom: 12,
        minZoom: 8,
        center: { lat: 1.3100128416300763, lng: 103.88438311096195 },
        fullscreenControl: false
      },
      map: null,
      google: null
    }
  },
  computed: {
    mapConfig () {
      return { ...this.initialMapConfig }
    },
    latLngs () {
      return this.results.map(({ property }) => property.location.latLng)
    }
  },
  async mounted () {
  },
  activated () {
    setTimeout(() => {
      EventBus.$emit('map-fitbounds', { latLngs: this.latLngs })
    }, 80)
  },
  watch: {
    latLngs: {
      immediate: true,
      handler (nV, oV) {
        EventBus.$emit('map-fitbounds', { latLngs: this.latLngs })
      }
    }
  },
  methods: {
    handleMapLoaded () {
      this.$emit('mapLoaded')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  #map-canvas {
    min-height: 360px;
    height: 100%;
  }
</style>
