<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
        :popUp="popUp"
      />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import EventBus from '../../EventBus'
import GoogleOverlayPopup from './GoogleOverlayPopup'

export default {
  name: 'GoolgeMapLoader',
  props: {
    mapConfig: Object,
    apiKey: String
  },
  data () {
    return {
      debug: process.env.NODE_ENV === 'development',
      google: null,
      map: null,
      popUp: null // Define overlayPopUp for property price
    }
  },
  async mounted () {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap()
    EventBus.$on('map-fitbounds', this.fitBounds)
  },
  beforeDestroy () {
    EventBus.$off('map-fitbounds')
  },
  methods: {
    initializeMap () {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, {
        ...this.mapConfig,
        zoomControlOptions: {
          position: this.google.maps.ControlPosition.RIGHT_CENTER
        }
      })
      this.popUp = GoogleOverlayPopup(this.google)
      this.$emit('loaded')
    },
    /**
     * @param latLngs<{lat: number, lng: number}[]> - collections of latLng
     * @param options.padding
     */
    fitBounds ({ latLngs, options = { padding: 32 } } = {}) {
      const k = new this.google.maps.LatLngBounds()
      latLngs.forEach(d => {
        k.extend(d)
      })
      this.map.fitBounds(k, options.padding)
      if (this.debug) console.log('%c ran map.fitBounds', 'background: orange; color: #333;')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .google-map {
    background: #f3f3f3;
    min-height: 360px;
    height: 100%;
  }
</style>
