<template>
  <section class="many-map">
    <SearchManyListSorting
      class="many-map--sorting-bar is-narrow"
      :layoutMode="$mq.all"
      @search="handleSearch" />
    <div class="many-map--search-bar">
      <keep-alive>
        <SearchBarManyMobile
          :queryParams="lv2State.queryParams"
          locationColCss="is-12"
          :layoutMode="$mq.all"
          @search="handleSearch"/>
      </keep-alive>
      <!-- <div class="is-size-7">{{ swiperProps }}</div> -->
      <progress-indicator class="wl-progress-bar--color" name="search-inprogress"></progress-indicator>
    </div>
    <div class="many-map--canvas">
      <div class="map-wrapper">
        <SearchMapCanvas :isLoading="isSearching" :results="availability.results" />
      </div>
    </div>
    <div class="many-map--listing">
      <SwiperContainer
        v-if="$route.name === 'searchMap' || !isSearching"
        ref="swiper"
        :swiperConfig="swiperConfig"
        @init="swiperInited"
        v-model="swiperProps">
        <template slot-scope="{ swiper }">
          <SwiperSlides
            class="many-map--listing-thumb"
            :swiper="swiper"
            :idx="idx"
            v-for="(it, idx) in availability.results"
            :key="it.property.propertyCode"
            @click.native.capture.stop="showPreviewModal(it)"
            >
            <div :class="['many-map--property-slide is-clearfix', activeSlideContent.property.propertyCode === it.property.propertyCode ? 'selecting' : '']">
              <div class="is-pulled-left gallery-hover">
                <i class="gallery-hover--img" v-lazy:backgroundImage="it.property.heroImage.url"/>
              </div>
              <div class="is-size-7 has-text-weight-semibold has-text-primary" @click.prevent="{}">{{ it.property.name }}</div>
              <div class="has-text-right">
                <span class="is-size-7">from</span>&nbsp;<span class="is-size-7 has-text-weight-semibold has-text-dark">{{ it.packages[0].unitAdjustedDisplayRate | currencyObj( { factionDigits: 0 }) }}</span>
              </div>
            </div>
          </SwiperSlides>
        </template>
      </SwiperContainer>
    </div>
  </section>
</template>

<script>
import SearchMapCanvas from '../components/SearchMapCanvas'
import SearchManyListSorting from '../components/SearchManyListSorting'
import SearchManyPagination from './SearchManyPagination'

export default {
  name: 'SearchManyMapWide',
  components: {
    [SearchMapCanvas.name]: SearchMapCanvas,
    'SearchBarManyMobile': () => import(/* webpackChunkName: "mobile" */'../components/SearchBarManyMobile'),
    [SearchManyListSorting.name]: SearchManyListSorting,
    // [PropertyList.name]: PropertyList,
    [SearchManyPagination.name]: SearchManyPagination,
    'SwiperContainer': () => import(/* webpackChunkName: "mobiledefer" */'../components/swiper/SwiperContainer'),
    'SwiperSlides': () => import(/* webpackChunkName: "mobiledefer" */'../components/swiper/SwiperSlides')
  },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      nights: 1,
      inactive: false,
      map: undefined,
      swiper: undefined,
      timer: undefined,
      swiperProps: {},
      swiperConfig: {
        slidesPerView: 'auto',
        spaceBetween: 12,
        centeredSlides: true
      }
    }
  },
  computed: {
    availability () {
      return this.lv2State.searchMany.availability
    },
    isSearching () {
      return this.lv2State.searchMany.isSearching
    },
    selected () {
      return this.lv2State.searchMany.selected
    },
    activeSlideContent () {
      return this.availability.results[this.swiperProps.activeIndex]
    }
  },
  mounted () {
  },
  watch: {
    'swiperProps': {
      // immediate: true,
      deep: true,
      handler (nV) {
        if (this.activeSlideContent == null) return
        this.$lv2Store.setSearchMany({ selected: this.activeSlideContent.property.propertyCode })
      }
    },
    'isSearching': {
      immediate: true,
      handler (nV) {
        if (nV) {
          this.$progress.show('search-inprogress')
        } else {
          this.$progress.hide('search-inprogress')
        }
      }
    },
    'selected': 'watchSelected'
  },
  methods: {
    handleSearch (val) {
      this.$emit('search', val)
    },
    watchSelected (propertyCode = '') {
      this.$nextTick(() => {
        this.scrollToItem()
      })
    },
    swiperInited () {
      this.watchSelected(this.selected)
    },
    showPreviewModal (propertyPackage) {
      this.$emit('select', propertyPackage)
    },
    scrollToItem () {
      const slideIdx = this.availability.results.findIndex(({ property } = {}) => (property.propertyCode === this.selected))
      if (this.$refs.swiper) {
        this.$refs.swiper.swiper.slideTo(slideIdx)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .many-map--property-slide {
    // box-shadow: $card-shadow-simple;
    padding: $bleed;
    background-color: $white;
    border-radius: $bleed / 2;
    transition: box-shadow 80ms $selected-transition 500ms;
    box-shadow: inset 0 0 0 rgba(255,255,255, 0);
    &.selecting {
      box-shadow: $selected-box-shadow;
    }
  }
  .gallery-hover {
    width: 50px;
    height: 50px;
    padding-right: $bleed;
  }
  .gallery-hover--img {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    background-position: 50% 20%;
    background-size: cover;
    transition: 400ms opacity ease-in;
    &[lazy=loaded] {
      opacity: 1;
    }
  }
  .many-map--listing-thumb {
    width: 180px;
    flex-shrink: 0;
    touch-action: none;
    user-select: none;
  }
  $many-map--listing-width: 100%;
  .many-map--search-bar {
    border-top: 1px solid $list-border-color;
    border-bottom: 1px solid $list-border-color;
    // padding: $bleed * 2 0;
    // padding-bottom: 0;
    background-color: $white-ter;
    position: fixed;
    top: 45px;
    z-index: $_jh_fullscreen-z-index - 1;
    width: 100%;
  }
  .many-map--sorting-bar {
    border-top: 1px solid $list-border-color;
    padding: 0 $bleed 0 $bleed * 2;
    background-color: $white-ter;
    position: fixed;
    top: 0;
    z-index: $_jh_fullscreen-z-index - 1;
    width: 100%;
  }
  .many-map--listing {
    height: 75px;
    width: 100%;
    position: fixed;
    bottom: 48px;
    z-index: $_jh_fullscreen-z-index - 2;
    user-select: none;
  }
  .many-map /deep/ .search-bar-mobile--pop-content {
    width: $many-map--listing-width;
    height: 100%;
    // margin: auto;
    z-index: $_jh_fullscreen-z-index;
  }
  .many-map--results {
    padding-top: $bleed * 2;
  }
  .many-map--canvas {
    position: fixed;
    // padding-top: 105px;
    top: 105px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 105px);
  }
  .results {
    // padding-top: 0;
  }
  .map-wrapper {
    position: sticky;
    top: 64px;
    height: 100%;
  }
</style>
