<script>
import smoothScroll from '../../plugins/smoothScroll'

export default {
  name: 'GoogleOverlayPropertyPrice',
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    popUp: {
      type: Function,
      required: true
    },
    property: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    packages: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      debug: process.env.NODE_ENV === 'development',
      toolTip: null,
      node: null,
      panAble: true
    }
  },
  computed: {
    latLng () {
      return this.property.location && this.property.location.latLng
    },
    propertyCode () {
      return this.property.propertyCode
    },
    propertyName () {
      return this.property.name
    },
    isSelecting () {
      return this.lv2State.searchMany.selected === this.propertyCode
    },
    lowestPckg () {
      return this.packages[0] || {}
    }
  },
  mounted () {
    this.node = this.createNode()
    this.init()
  },
  beforeDestroy () {
    this.toolTip.setMap(null)
    if (this.debug) console.log(`%c Removed GoogleOverlayPropertyPrice ${this.property.name}`, 'background-color: #ee0000; color: #fff;')
  },
  watch: {
    'propertyCode': {
      handler (nV) {
        if (nV != null) {
          if (this.debug) console.log('%c Reinit GoogleOverlayPropertyPrice', 'background-color: red; color: #fff;')
          if (this.toolTip != null) this.toolTip.setMap(null)
          this.node = this.createNode()
          this.init()
        } else {
          if (this.toolTip != null) this.toolTip.setMap(null)
        }
      }
    },
    'lowestPckg': {
      deep: true,
      handler (nV) {
        this.node.innerHTML = this.$root.$options.filters.currencyObj(this.lowestPckg.unitAdjustedDisplayRate, { factionDigits: 0 })
      }
    },
    'isSelecting': {
      handler (nV) {
        this.toggleSelected(nV)
      }
    }
  },
  render () {}, // eslint-disable-line
  methods: {
    createNode () {
      const k = document.createElement('a')
      k.classList.add('popup-bubble-content')
      k.innerHTML = `${this.$root.$options.filters.currencyObj(this.lowestPckg.unitAdjustedDisplayRate, { factionDigits: 0 })}`
      k.addEventListener('click', () => {
        this.panAble = false
        const domId = '#pc' + this.propertyCode
        // Only scroll in tablet mode
        if (this.$mq.tablet) smoothScroll.scrollTo(domId, { offset: -150 })
        this.$lv2Store.setSearchMany({ selected: this.propertyCode })
      }, false)
      return k
    },
    init () {
      this.toolTip = new this.popUp(this.latLng, this.node, this.propertyCode) // eslint-disable-line
      this.toolTip.setMap(this.map)
      this.toggleSelected(this.isSelecting)
    },
    handleClicked () {
    },
    toggleSelected (isSelecting) {
      if (this.node == null) return
      const p = this.node.parentNode
      if (isSelecting && p != null) {
        p.style.zIndex = 2000
        p.classList.add('selecting')
        if (this.panAble) this.map.panTo(this.latLng)
      } else {
        p.style.zIndex = 1999
        p.classList.remove('selecting')
        this.panAble = true
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';
  .popup-tip-anchor {
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
  }
  /* The bubble is anchored above the tip. */
  .popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: /* TIP_HEIGHT= */ 8px;
    left: 0;
  }
  /* Draw the tip. */
  .popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0) perspective(1px);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: /* TIP_HEIGHT= */ 10px solid white;
    // box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
  }
  .selecting.popup-bubble-anchor::after {
    border-top-color: $primary; /* TIP_HEIGHT= */
  }
  /* The popup bubble itself. */
  .popup-bubble-content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-20%, -100%);
    /* Style the info window. */
    background-color: white;
    padding: $bleed/2 $bleed + $bleed/2;
    border-radius: $bleed / 2;
    font-family: sans-serif;
    font-size: $size-6;
    overflow-y: auto;
    max-height: 60px;
    box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
    transition: 300ms transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: $dark;

    .selecting & {
      background-color: $primary;
      color: $white;
      font-weight: 600;
      transform: scale(1.2) translate(-20%, -92%) perspective(0px);
    }
  }
</style>
