<template>
  <div>
    <component
      ref="map"
      :is="mapComponent"
      :query="$route.query"
      :params="$route.params"
      :selected="selected"
      @search="handleSearch"
      @select="showPreviewModal"/>
    <transition name="view-fade-slow">
      <router-view class="search-bar-mobile--pop" :params="params" :query="query"></router-view>
    </transition>
    <transition name="view-fade">
      <PpOverlay
        class="ov"
        v-if="$route.name === 'searchMapPreview'"
        style="background-color: rgba(0,0,0,.8)"
        :zIndex="2002"
        />
    </transition>
  </div>
</template>

<script>
import factories from '../factories'
import EventBus from '../EventBus'

export default {
  name: 'SearchManyMap',
  components: {
    'SearchManyMapTight': () => import(/* webpackChunkName: "map" */ './SearchManyMapTight'),
    'SearchManyMapWide': () => import(/* webpackChunkName: "map" */ './SearchManyMapWide')
  },
  props: {
    query: Object,
    params: Object
  },
  data () {
    return {
      nights: 1,
      map: undefined,
      previewModalVisibility: false
    }
  },
  computed: {
    availability () {
      return this.lv2State.searchMany.availability
    },
    isSearching () {
      return this.lv2State.searchMany.isSearching
    },
    mapComponent () {
      return this.$mq.mobile ? 'SearchManyMapTight' : 'SearchManyMapWide'
    },
    selected () {
      return this.lv2State.searchMany.selected
    }
  },
  created () {
    if (!this.lv2State.searchMany.isSearching && this.availability.results.length === 0) {
      this.$lv2Store.setQueryParams({ ...this.$route.query, ...this.$route.params })
      factories.many(this.$route.query)
        .then(() => {
          setTimeout(() => {
            this.$refs.map.scrollToItem()
          }, 300)
        })
    }
  },
  activated () {
    EventBus.$on('currency-changed', ({ currency } = {}) => {
      this.handleSearch({ currency }, true)
    })
    setTimeout(() => {
      this.$refs.map.scrollToItem()
    }, 300)
  },
  deactivated () {
    EventBus.$off('currency-changed')
    // source.search.cancel('new search')
  },
  methods: {
    // always replace the link in map view
    handleSearch (val) {
      const newQuery = { page: 1, ...this.lv2State.queryParams, ...this.$route.query, ...val }
      const { regionName, locationQuery, ...query } = newQuery
      this.$lv2Store.setQueryParams(newQuery)
      factories.many(query)
      this.$router.replace({ name: 'searchMap', params: { locationQuery: regionName || locationQuery }, query })
    },
    showPreviewModal ({ property, packages }) {
      const joinedGallery = [].concat(
        [{ s: property.heroImage, m: property.heroImage }],
        property.gallery)
      this.$lv2Store.setSearchOne({ property: { ...property, gallery: joinedGallery }, availability: { packages } })
      this.$nextTick(() => {
        this.$router.push({ name: 'searchMapPreview', params: this.$route.params, query: { ...this.$route.query, propertyCode: property.propertyCode } })
      })
      // this.previewModalVisibility = true
    }
  },
  metaInfo () {
    return {
      title: `Map: ${this.params.locationQuery} accommodations`,
      titleTemplate: (title) => title,
      meta: [
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0,maximum-scale=1.0' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .search-bar-mobile--pop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $_jh_fullscreen-z-index + 6;
    display: flex;
    flex-direction: column;
    width: 100vw;
    // @include tablet {
    //   right: inherit;
    //   width: 35%;
    //   overflow: hidden;
    // }
  }
  // .ov {
  //   @include tablet {
  //     right: inherit;
  //     width: 35%;
  //     overflow: hidden;
  //   }
  // }
  // .search-bar-mobile--pop /deep/ .property-preview--slides {
  //   width: 100%;
  // }
</style>
